import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import LogoLink from "../components/LogoLink"
import NwHelmet from "../components/NwHelmet"
import BrowserUpdateMessage from "../components/BrowserUpdateMessage"
import CookieBanner from "../components/CookieBanner"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  padding: 1rem;
  text-align: center;
  color: white;
  background: linear-gradient(
    0deg,
    ${props => props.theme.red[3]} 0%,
    ${props => props.theme.red[2]} 100%
  );

  a {
    color: white;
  }

  p {
    max-width: 70ch;
  }
`

const StyledLogoLink = styled(LogoLink)`
  margin: 0 auto 2rem;
`

export default function PageNotFound({ pageContext: { intl: { language, messages } } }) {
  // Since /404.html (the Swedish page) will always be displayed for paths that don't resolve
  // to a static file, the language for pages under /en/ will not change until the page renders
  // in the browser. For this reason, we don't show the content on server-side rendering.
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(true)
  }, [])

  return (
    <>
      <Wrapper>
        <NwHelmet lang={language} title={messages.pageNotFound} is404 />
        {show &&
          <>
            <StyledLogoLink large lang={language === "sv" ? "sv" : "en"} />
            <h1>{messages.pageNotFound}</h1>
            <p>{messages.pageNotFoundDescription}</p>
            <p>{messages.pageNotFoundContact} <a href={`mailto:${messages.emailAddress}`}>{messages.emailAddress}</a>.</p>
            <p><Link to={`/${language}`}>{messages.goBackHome}</Link></p>
          </>
        }
      </Wrapper>
      <BrowserUpdateMessage />
      <CookieBanner lang={language} />
    </>
  )
}
